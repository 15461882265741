/* eslint-disable implicit-arrow-linebreak */
import axios, { AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { store } from 'redux/store'
import Debug from 'debug'
import _isEmpty from 'lodash/isEmpty'

import { authActions } from 'redux/reducers/auth'
import sagaActions from 'redux/sagas/actions'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from 'utils/accessToken'
import { getRefreshToken, removeRefreshToken } from 'utils/refreshToken'
import { IUser } from 'redux/models/IUser'
import { IOffer } from 'redux/models/IOffer'
import { IPwaPrice } from 'redux/models/IPwaPrice'

const debug = Debug('ononoteam:api')
// @ts-ignore
const baseURL: string = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL,
})

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: { response: AxiosResponse }) =>
  axios
    .post(`${baseURL}/auth/refresh-token`, null, {
      headers: {
        Authorization: `Bearer ${getRefreshToken()}`,
      },
    })
    .then((tokenRefreshResponse) => {
      const { accessToken } = tokenRefreshResponse.data
      setAccessToken(accessToken)
      // eslint-disable-next-line
      failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
      return Promise.resolve()
    })
    .catch((error) => {
      store.dispatch(authActions.logout())
      store.dispatch(sagaActions.logout())
      return Promise.reject(error)
    })

// Instantiate the interceptor
createAuthRefreshInterceptor(api, refreshAuthLogic, {
  statusCodes: [401, 403],
})

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken()
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export const authMe = () =>
  api
    .get('user/me')
    .then((response): IUser => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const logoutApi = (refreshToken: string | null) =>
  axios
    .post(`${baseURL}/auth/logout`, null, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    })
    .then((response): {} => {
      removeAccessToken()
      removeRefreshToken()
      return response.data
    })
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const refreshToken = () =>
  api
    .post('auth/refresh-token')
    .then(
      (
        response,
      ): {
        access_token: string;
        refreshToken: string;
      } => response.data,
    )
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const login = (credentials: { email: string; password: string }) =>
  api
    .post('auth/login', credentials)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getLeads = (query: {
  offset: number;
  limit: number;
  bayer?: string;
  status?: string;
  offer?: string;
  funnel?: string;
  country?: string;
  source?: string;
  pp?: string;
  from?: string;
  to?: string;
}) =>
  api
    .get('leads/all', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getLeadsByPP = ({
  startDate,
  endDate,
  bayer,
}: {
  startDate: string | Date;
  endDate: string | Date;
  bayer?: string;
}) =>
  api
    .get('leads/by-pp', {
      params: {
        from: startDate,
        to: endDate,
        bayer,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getBuyers = (
  group: string,
  from: string | Date,
  to: string | Date,
  groupBy:
    | 'geo'
    | 'buyer'
    | 'campaign'
    | 'day'
    | 'charts'
    | 'total'
    | 'buyerWithTotal'
    | 'offer'
    | 'mix'
    | 'mixDay'
    | 'agent',
  name: string[] | string,
  country?: string[] | string,
  company?: string[] | string,
  source?: string,
  network?: string[] | string,
  device?: string[] | string,
  agent?: string[] | string,
) =>
  api
    .get('leads/buyers', {
      params: {
        group,
        from,
        to,
        groupBy,
        country,
        name,
        company,
        source,
        network,
        device,
        agent,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getKeitaroStats = (
  date: string | Date,
  campaign?: string,
  name?: string,
) =>
  api
    .get('leads/keitaro-stats', {
      params: {
        date,
        name,
        campaign,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getBuyersNames = () =>
  api
    .get('leads/buyers-names')
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const saveStats = (stats: {
  name: string;
  campaing: string;
  geo: string;
  spent: number;
  unique_clicks: number;
  leads: number;
  sales: number;
  payout: number;
  isTest?: boolean;
  date: string;
  pwaName: string;
  currency: string;
  group: string;
  fee: number;
  agentName?: string;
  isGoogleStats?: boolean;
}) =>
  api
    .post('leads/stats', stats)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateStats = (id: number, stats: any) =>
  api
    .put(`leads/stats/${id}`, stats)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getStats = (query: {
  campaing?: string;
  geo?: string;
  name?: string;
  isTest?: boolean;
  from?: string;
  to?: string;
  offset?: number;
  limit?: number;
  group?: string;
}) =>
  api
    .get('leads/stats', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getOffers = (query: {
  offer?: string;
  geo?: string;
  model?: string;
  name?: string;
  limit?: number;
  offset?: number;
  order?: string;
  by?: string;
}) =>
  api
    .get('offers', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const saveOffer = (offer: Omit<IOffer, 'id'>) =>
  api
    .post('offers', offer)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateOffer = (offer: Partial<IOffer> & { id: number }) =>
  api
    .put(`offers/${offer.id}`, offer)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateOfferStatusById = (id: number, status: string) =>
  api
    .patch(`offers/update-status/${id}`, {
      status,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateOfferNames = (id: number, names: string[]) =>
  api
    .patch(`offers/update-names/${id}`, {
      names,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateOfferStopedNames = (id: number, stopedNames: string[]) =>
  api
    .patch(`offers/update-stoped-names/${id}`, {
      stopedNames,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const deleteOffer = (id: number) =>
  api
    .delete(`offers/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getCampaignsNames = (name?: string, group?: string) =>
  api
    .get('leads/campaigns-names', {
      params: {
        name,
        group,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getKeitaroCampaning = (query: {
  from?: string;
  to?: string;
  name: string[] | string;
  group: string;
}) =>
  api
    .get('leads/filters', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getKeitaroCampaningGroup = (query: { from: string; to: string }) =>
  api
    .get('leads/keitaro-campaning_group', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getKeitaroCampaingAdditional = (query: { name: string }) =>
  api
    .get('leads/keitaro-campaing-additional', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const saveUniqueCampaigns = (campaign: any) =>
  api
    .post('leads/unique-campaigns', {
      campaign,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getUniqueCompany = (query: {
  limit: number;
  offset: number;
  name?: string;
  campaing?: string;
  geo?: string;
}) =>
  api
    .get('leads/unique-campaigns', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateUniqueCompany = (id: number, campaign: any) =>
  api
    .put(`leads/unique-campaigns/${id}`, {
      campaign,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getOffersByCampaign = (query: {
  campaign?: string;
}): Promise<string[]> =>
  api
    .get('leads/offersByCampaign', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getSubCompanyValue = (query: {
  campaign?: string;
  offer?: string;
}): Promise<{
  [key: string]: string[];
}> =>
  api
    .get('leads/sub-company-value', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getStatsUniqueCampaigns = (query: {
  from?: string;
  to?: string;
  buyer?: string | string[];
  company?: string;
}) =>
  api
    .get('leads/stats-unique-campaigns', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getFinanceCompanyStats = (query: { from?: string; to?: string }) =>
  api
    .get('finance/company-stats', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createFinanceCompanyGroup = (name: string, group: string | null) =>
  api
    .post('finance/group', {
      name,
      group,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const addFinanceCompanySpend = (companySpend: {
  groupid: number;
  date: string;
  spent: number;
}) =>
  api
    .post('finance/spend', companySpend)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getCompanySpendGroups = (query: { from?: string; to?: string }) =>
  api
    .get('finance/groups', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getCompanySpendGroupByDay = (query: {
  from?: string;
  to?: string;
  groupid?: number;
}) =>
  api
    .get('finance/view-spend-group-by-day', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const addBuyerSalary = (salary: {
  name: string;
  salary?: number;
  bonus?: number;
}) =>
  api
    .post('finance/add-salary', salary)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createCompanyGroup = (name: string) =>
  api
    .post('finance/company-group', {
      name,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getBuyersSalary = (buyer: string) =>
  api
    .get('finance/buyers-salary', {
      params: {
        buyer,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getAllUsers = () =>
  api
    .get('user/all')
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateUser = (userId: string, data: Partial<IUser>) =>
  api
    .post('user/update', {
      id: userId,
      ...data,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createUser = (user: Partial<IUser>) =>
  api
    .post('user/create', user)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const deleteUser = (id: string) =>
  api
    .post('user/delete', {
      id,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const setTheme = (userId: string, theme: string) =>
  api
    .post('user/set-theme', {
      id: userId,
      theme,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getOffersRequests = (limit: number, offset: number) =>
  api
    .get('offers/requests', {
      params: {
        limit,
        offset,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const saveOfferRequest = (offer: { text: string }) =>
  api
    .post('offers/requests', offer)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateOfferRequestStatusById = (id: string, status: string) =>
  api
    .patch(`offers/requests/${id}`, {
      status,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const calculateSalary = (spend: number, revenue: number) =>
  api
    .post('finance/calculate-salary', {
      spend,
      revenue,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getPwa = (
  take: number,
  skip: number,
  geo?: string,
  name?: string,
) =>
  api
    .get('pwa', {
      params: {
        take,
        skip,
        geo,
        name,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createPwa = (data: any) =>
  api
    .post('pwa', data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updatePwa = (id: number, data: any) =>
  api
    .put(`pwa/${id}`, data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const deletePwa = (id: number) =>
  api
    .delete(`pwa/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const fetchOffersFromKeitaro = () =>
  api
    .get('offers/fetchOffers')
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const deleteCompanySpend = (data: { date: string; groupName: string }) =>
  api
    .delete('finance/delete-company-spend', {
      params: {
        date: data.date,
        groupName: data.groupName,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getOffersLogs = (data: {
  limit: number;
  offset: number;
  id: number;
}) =>
  api
    .get('offers/logs', {
      params: data,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getDeepLinks = (data: {
  limit: number;
  offset: number;
  name?: string;
}) =>
  api
    .get('deep-link/all', {
      params: data,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createDeepLink = (data: {
  name: string;
  url: string;
  secondUrl: string;
  source: string;
}) =>
  api
    .post('deep-link', data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getSlices = (data: {
  take?: number;
  skip?: number;
  from: string;
  to: string;
}) =>
  api
    .get('finance/slices', {
      params: data,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createSlices = (data: {
  name: string;
  spent: number;
  date: string;
}) =>
  api
    .post('finance/slices', data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createAgent = (data: {
  name: string;
  dashboardType: string;
  fee: number;
}) =>
  api
    .post('agents', data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getAgents = (data: {
  limit: number;
  offset: number;
  group?: string;
}) =>
  api
    .get('agents', {
      params: data,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateAgent = (data: {
  id: number;
  name: string;
  dashboardType: string;
  fee: number;
}) =>
  api
    .put('agents', data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const deleteAgent = (id: number) =>
  api
    .delete(`agents/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getInstallPrice = (query: { skip: number; name?: string }) =>
  api
    .get('/pwa/installPrice', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getInstallPriceForStats = (query: { campaign: string, source: string }) =>
  api
    .get('/pwa/for-stats', {
      params: query,
    })
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const createInstallPrice = (data: Omit<IPwaPrice, 'id'>) =>
  api
    .post('/pwa/installPrice', data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const updateInstallPrice = (id: string, data: Omit<IPwaPrice, 'id'>) =>
  api
    .put(`/pwa/installPrice/${id}`, data)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const deleteInstallPrice = (id: string) =>
  api
    .delete(`/pwa/installPrice/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })

export const getOneInstallPrice = (id: string) =>
  api
    .get(`/pwa/installPrice/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      debug('%s', error)
      throw _isEmpty(error.response.data?.message)
        ? error.response.data
        : error.response.data.message
    })
